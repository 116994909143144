import React,{useRef, useState, useEffect} from 'react';
import { List, ListItemButton, ListItemText, ListItemIcon, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
function NestedList({links}) {
  const theme = useTheme();

  const [selectedIndex, setSelectedIndex] = React.useState(links[0].href);
  const navbarRef = useRef(null);

  useEffect(() => {
    // Függvény az "active" osztály változásának figyelésére
    const observeActiveClassChanges = () => {
      // A navbar konténerre vonatkozó MutationObserver létrehozása
      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          // Ellenőrizzük, hogy az "active" osztály változott-e a gyerekelemek között
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const targetElement = mutation.target;            
            if (targetElement.classList.contains('active')) {
              // Az aktív elem megváltozott, ide helyezd a kívánt műveleteket
              const targetElementHref = mutation.target.attributes.href.value;
              if (targetElementHref) setSelectedIndex(targetElementHref);
            }
          }
        }
      });

      // Observer konfiguráció: figyeljük a gyerekelemek "class" attribútumának változásait
      const observerConfig = { attributes: true, subtree: true, attributeFilter: ['class'] };

      // A navbar konténerre vonatkozó MutationObserver indítása
      observer.observe(navbarRef.current, observerConfig);

      // Cleanup függvény: az observer leállítása, amikor a komponens unmountolódik
      return () => observer.disconnect();
    };

    // Aktív osztály változásának figyelése
    observeActiveClassChanges();
  }, []);

  return (
    <Box>
      <List component="nav" className="menu-list" id="navbar-scrollspy-container" ref={navbarRef}>      
        {links.map((link, index) => (
          <a key={index} href={link.href} target='_self'>
            <ListItemButton 
            selected={selectedIndex === link.href}         
            sx={{
              '&.Mui-selected': {
                backgroundColor: theme.palette.listItem.selected.backgroundColor,
                '&:hover': {
                  backgroundColor: theme.palette.listItem.hover.backgroundColor,
                },
              },
            }}>
              <ListItemIcon>
                {link.icon}
              </ListItemIcon>
              <ListItemText primary={link.text} />
          </ListItemButton>
        </a>          
        ))}     
        </List> 
      </Box>             
  );
}

export default NestedList;