import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { CustomScroll } from "react-custom-scroll";
import ReactMarkdown from 'react-markdown';

import "./Chat.scss";

const Chat = () => {
  const now = new Date();
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([
    { role: "bot", content: "Hi, I'm Leila! \n\nFeel free to ask, I will try to help.", timestamp: now}
  ]);
  const [threadId, setThreadId] = useState(null); // Store thread ID
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const messagesEndRef = useRef(null);
  const textFieldRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  // Function to create a new thread and store its ID
  const createThread = async () => {
    try {
      const apiUrl = "/api/thread"; // Ensure this matches your API endpoint for creating a thread
      const { data } = await axios.get(apiUrl);
      setThreadId(data.threadId); // Assuming the API returns an object with a threadId property
    } catch (error) {
      console.error("Error creating thread:", error);
    }
  };

  // Call createThread when the component mounts
  useEffect(() => {
    createThread();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      textFieldRef.current.focus();  
    }
  }, [isLoading]);

  const sendMessage = async () => {

    if (!input.trim() || !threadId) return; // Prevent sending empty messages or if thread ID is not set
    
    setIsLoading(true);
    
    const userMessage = input;
   
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: userMessage, timestamp: now },
    ]);
    
    setInput(""); // Clear input after sending

    try {
      const apiUrl = `/api/message`; // Ensure this matches your API endpoint for sending messages
      const requestBody = {
        message: input,
        threadId, // Use the stored thread ID
      };

      const { data } = await axios.post(apiUrl, requestBody);

      const botResponse = data.messages[0][0].text.value;

      // const botResponse = 'ok';

      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "bot", content: botResponse, timestamp: now },
      ]);

    } catch (error) {
      console.error("Error sending message:", error);
      // Optionally handle the error visually in the chat
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "bot",
          content: "Sorry, there was an error processing your message.",
        },
      ]);
    } finally {
      setIsLoading(false); // End loading regardless of outcome           
      textFieldRef.current.focus();       
    }
  };

  function MarkdownText( text )
  {
    return <ReactMarkdown>{text}</ReactMarkdown>;
  }

  function dateToStrong(datetime)
  {
    return datetime.getFullYear().toString(10)
    +"-"+ (datetime.getMonth()+1).toString(10).padStart(2,'0')
    +"-"+ datetime.getDate().toString(10).padStart(2,'0')
    +" "+ datetime.getHours().toString(10).padStart(2,'0')
    +":"+ datetime.getMinutes().toString(10).padStart(2,'0');
  }

  return (
    <Box className="chat-container">
      <CustomScroll  heightRelativeToParent="calc(100%)" keepAtBottom="true">
        <Box className="messages-container" sx={{ p:2, overflow: 'auto' }}>
          {messages.map((message, index) => (
            <Tooltip key={index} title={dateToStrong(message.timestamp)}>
              <Box key={index}  className={`message ${message.role}`} sx={{ boxShadow: 3 }}>
                {MarkdownText(message.content)}
              </Box>
            </Tooltip>
          ))}
          {/* Loading indicator */}
          {isLoading && <Box sx={{ width: '100%' }}><LinearProgress /></Box>}
          <div ref={messagesEndRef} />
        </Box>
      </CustomScroll>
      
      <Grid container spacing={0} alignItems="center" className="input-container" sx={{ p:2 }}>
          <Grid xs>
            <TextField id="message-input" label="Question" variant="standard"  
              value={input}         
              placeholder="Type a message..."
              onChange={(e) => setInput(e.target.value)}  
              onKeyUp={(e) => {
                if (isLoading){ e.preventDefault(); return true; }                  
              }}
              onKeyDown={(e) => {
                if (isLoading){ e.preventDefault(); return true; }
                if (e.key === 'Enter')
                {
                  e.preventDefault();
                  setInput(e.target.value);
                  sendMessage();
                }
              }}
              color={isLoading?'warning':'primary'}
              sx={{width:"100%"}}
              ref={textFieldRef}
              autoFocus
            />
          </Grid>
          <Grid xs="auto">
            <LoadingButton
            onClick={sendMessage}
            endIcon={<SendIcon />}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
            sx={{marginLeft:"20px"}}
          >
            <span>Send</span>
          </LoadingButton>
          </Grid>
        </Grid>        
    </Box>
  );
};

export default Chat;