import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Avatar from '@mui/material/Avatar';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

export default function MultiActionAreaCard() {
  
  const experts = [
    {
      name:"Johann",
      spec:"Relationship Communication and Conflict Resolution",
      desc:"Hello, I am Dr. Johann Oberhauser, a dedicated specialist in relationship communication and conflict resolution. My passion for this field stems from my own challenging childhood experiences, where I witnessed frequent arguments between my parents. ",
      img:"e_1.png"
    }
    // ,
    // {
    //   name:"Helga",
    //   spec:"Sexual Education and Adolescent Sexuality",
    //   desc:"Greetings, I am Dr. Helga Steinberger, and I am passionate about providing clear and accessible sexual education to young people. My goal is to ensure that the next generations enter adulthood well-informed and safe. ",
    //   img:"e_2.png",
    // }
    // ,
    // {    
    //   name:"Markus",
    //   spec:"Sexual Dysfunctions and Therapy",
    //   desc:"Hi, I'm Dr. Markus Weiss, a specialist in the treatment of sexual dysfunctions. My work focuses on helping my patients reclaim a fulfilling and satisfying sexual life. My deep interest in both biology and psychology allows me to blend these disciplines effectively in my practice. ",
    //   img:"e_3.png",
    // }
    // ,
    // {    
    //   name:"Erika",
    //   spec:"LGBTQ+ Sexual Health and Relationships",
    //   desc:"Hello, I'm Dr. Erika Hofer, and I specialize in LGBTQ+ sexual health and relationships. My commitment to this field is driven by a desire to support and empower individuals within the LGBTQ+ community. ",
    //   img:"e_4.png",
    // }
  ];

  return (
    experts.map((expert, index) => (
    <Grid xs={12} md={12} sx={{p:1}}>
      <Card sx={{ width:'100%' }}>
        <CardActionArea>
       



        <CardContent sx={{ flex: '1 0 auto' }}>
          <Grid container key={index}>          
            <Grid xs="auto">
              <Avatar
                alt={expert.name}
                src={expert.img}
                sx={{ width: 60, height: 60 }}
              />             
            </Grid>
            <Grid xs sx={{pl:2}}>
              <Typography component="div" variant="h5">
              {expert.name}                
              </Typography>
              <Typography variant="subtitle2" color="text.secondary" component="div">
                {expert.spec}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>

          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {expert.desc}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button variant="contained" disabled="true" endIcon={<InsertInvitationIcon />}>
            Booking your appointment
          </Button>          
        </CardActions>
      </Card>
    </Grid>
    ))
  );
}