import "../App.scss";

import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Alert from '@mui/material/Alert';

function Howtouse() {

  return (
    <Grid container spacing={0} sx={{textAlign:'justify', height:'calc(100vh - 80px)'}} >  
    
        <Grid xs={12} sx={{p:2, order:{ xs: 1, md: 1}}}>

            <section>
                <h5>Welcome to Lustiq Sex Coach!</h5>
                <Typography variant="h2" sx={{ mt:2, pt:3, textTransform: 'uppercase' }}><strong>Unlocking Intimacy, One Conversation at a Time</strong></Typography>
                    <p>At Lustiq Sex Coach, we are dedicated to enhancing relationships and intimacy through personalized, AI-driven guidance. Our mission is to help individuals and couples improve communication, deepen their emotional and physical connections, and explore their desires in a safe and supportive environment.</p>
                <Typography variant="h2" sx={{ mt:2, pt:3, textTransform: 'uppercase' }}><strong>Who We Are</strong></Typography>
                    <p>Lustiq Sex Coach is an innovative platform powered by advanced AI technology. We offer a comprehensive suite of services designed to support your journey towards a more fulfilling and connected relationship. Whether you're looking to spice up your love life, resolve conflicts, or simply understand your partner better, our tools and resources are here to assist you.</p>
                <Typography variant="h2" sx={{ mt:2, pt:3, textTransform: 'uppercase' }}><strong>What We Offer</strong></Typography>
                <ol>
                <li>
                <p><strong>Educational Content</strong>: Dive into our extensive library of articles, podcasts, books, and workshops covering sexual health, intimacy, and relationship dynamics. Learn about the latest research and tips to enhance your relationship.</p>
                </li>
                <li>
                <p><strong>Personalized Advice</strong>: Receive tailored advice based on your unique preferences and concerns. Our AI coach listens and provides practical, actionable suggestions to help you achieve your relationship goals.</p>
                </li>
                <li>
                <p><strong>Practical Tools</strong>: Utilize conversation starters, activity ideas, and guided experiences designed to promote open dialogue about desires, expectations, and relationship needs. Strengthen your bond through shared activities and meaningful conversations.</p>
                </li>
                <li>
                <p><strong>Emotional Support</strong>: Gain confidence in discussing sexuality and relationship needs. Our AI coach offers a safe space for exploring your desires and addressing any concerns you may have.</p>
                </li>
                <li>
                <p><strong>Routine and Ritual Creation</strong>: Discover new routines and rituals that promote connection and intimacy. From date night ideas to daily check-ins, we help you build habits that strengthen your relationship.</p>
                </li>
                <li>
                <p><strong>Problem-Solving Strategies</strong>: Learn effective conflict-resolution techniques and problem-solving strategies. Navigate challenges with confidence and build a stronger, more resilient relationship.</p>
                </li>
                <li>
                <p><strong>Exploration of Desires</strong>: Safely explore your sexual desires and kinks. Our AI coach provides guidance on how to communicate and experiment with your partner in a respectful and consensual manner.</p>
                </li>
                <li>
                <p><strong>Professional Resources</strong>: For issues requiring professional intervention, we suggest therapy or counseling resources. Our support extends to providing assistance during crisis situations, ensuring you have the help you need when you need it most.</p>
                </li>
                </ol>
                <p><strong>Privacy and Anonymity</strong></p>
                <p>We understand the sensitive nature of discussing intimate topics. Lustiq Sex Coach ensures complete anonymity and privacy for all users. Your conversations and data are kept secure and confidential, allowing you to explore and grow without fear or judgment.</p>
                <p><strong>Join Us Today</strong></p>
                <p>Embark on a journey towards a more intimate and connected relationship with Lustiq Sex Coach. Discover the tools, resources, and support you need to unlock the full potential of your relationship. Whether you're just starting out or looking to reignite the spark, we're here to help every step of the way.</p>
                <p>Welcome to a new era of intimacy and connection. Welcome to Lustiq Sex Coach.</p>
            </section>
            <Box>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <Alert severity="success" sx={{borderRadius: '12px'}}>

                            <Typography variant="h2" sx={{ textTransform: 'uppercase' }}>
                                <strong>What you can ask about</strong>
                            </Typography>
                            <Box>
                                <ul>
                                <li>Relationship Dynamics and Conflict Resolution:
                                <ul>
                                    <li>How to resolve conflicts?</li>
                                    <li>Communication strategies in a relationship.</li>
                                </ul>
                                </li>
                                <li>Intimacy and Sexuality:
                                <ul>
                                    <li>Sexual health and hygiene.</li>
                                    <li>Trying new things in the bedroom.</li>
                                </ul>
                                </li>
                                <li>Self-Esteem and Body Image:
                                <ul>
                                    <li>How to boost self-confidence?</li>
                                    <li>Developing and maintaining a positive body image.</li>
                                </ul>
                                </li>
                                <li>Sexual Orientation and Identity:
                                <ul>
                                    <li>LGBTQ+ questions and answers.</li>
                                    <li>Coming out and self-acceptance.</li>
                                </ul>
                                </li>
                                <li>Healthy Sexual Practices:
                                <ul>
                                    <li>Safe sex and contraception.</li>
                                    <li>Sexual wellness and pleasure.</li>
                                </ul>
                                </li>

                                <li>Dating and Finding a Partner:
                                <ul>
                                    <li>Online dating tips and tricks.</li>
                                    <li>First date etiquette and preparation.</li>
                                </ul>
                                </li>

                                <li>Emotional Well-being in Relationships:
                                <ul>
                                    <li>Managing emotional stress and anxiety.</li>
                                    <li>Building and maintaining trust.</li>
                                </ul>
                                </li>
                                <li>Consent and Boundaries:
                                <ul>
                                    <li>Understanding and communicating consent.</li>
                                    <li>Setting and respecting personal boundaries.</li>
                                </ul>
                                </li>
                                </ul>                  
                            </Box>

                        </Alert>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Alert severity="warning" sx={{borderRadius: '12px'}}>

                            <Typography variant="h2" sx={{ textTransform: 'uppercase' }}>
                                <strong>What you can't ask about</strong>
                            </Typography>             
                            <Box>
                                <ul>
                                <li>Sexually explicit content: <ul><li>Topics involving explicit sexual content are prohibited.</li></ul></li>
                                <li>Violence and coercion: <ul><li>Any content promoting violence, coercion, or non-consensual behavior is not allowed.</li></ul></li>
                                <li>Harassment and abuse: <ul><li>Discussions or solicitations related to harassment or abuse, including sexual harassment, are prohibited.</li></ul></li>
                                <li>Illegal activities: <ul><li>Content related to illegal sexual activities or behaviors is strictly forbidden.</li></ul></li>
                                <li>Inappropriate solicitation of personal information: <ul><li>Asking for or sharing personal information in an inappropriate or unethical manner, especially related to sexual matters, is not permitted.</li></ul></li>
                                </ul>                  
                            </Box>  

                        </Alert>                    
                    </Grid>
                </Grid>
            </Box>
        </Grid>

    </Grid>
  );
}

export default Howtouse;