import "../App.scss";

import React, { useState, useEffect } from "react";
import Chat from "../Chat.js";
import Blog from "../Blog.js";
import NestedList from "../Components/NestedList.js";
import ExprtsCard from "../Components/ExprtsCard.js";
import BasicSpeedDial from "../Components/SpeedDial.js";
import { styled,createTheme, ThemeProvider } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import FeedIcon from '@mui/icons-material/Feed';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';

const theme = createTheme({
  typography: {
    fontSize:16,
    h1: {
      fontSize: 30,
    },
    h2: {
      fontSize: 22,  
      fontWeight: 600
    },
    h3: {
      fontSize: 18
    },  
    overline:{
      fontSize: 16,
      color:'#97DEF0'         
    }      
  },  
  palette: {
    primary: {
      main: '#fa2243'      
    },
    secondary: {
      main: '#97DEF0'      
    },
    listItem:{
      selected:{
        backgroundColor: "#97DEF055"
      },
      hover:{
        backgroundColor: '#97DEF099'
      },   
    }
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function Home() {

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(!isMobile);
  const links = [
    { href: '#section-1', text: 'How to use', icon: <NotListedLocationIcon/> },
    { href: '#section-2', text: 'What you can ask', icon: <ThumbUpIcon/> },
    { href: '#section-3', text: 'What you can`t ask', icon: <ThumbDownIcon/> },
    { href: '#section-4', text: 'Experts', icon: <Diversity1Icon/> },
    { href: '#section-5', text: 'Related articles', icon: <FeedIcon/> }
  ];
  useEffect(() => {
    setExpanded(!isMobile);
  }, [isMobile]);

  const handleChange = () => {
    setExpanded(!expanded);
  };  

  return (
              <Grid container spacing={0} sx={{height:'calc(100vh - 80px)'}} > 
                <BasicSpeedDial subPages={links}/>               
                <Grid xs={12} md="auto" sx={{p:2, backgroundColor: '#F2F2F2', display: { xs: 'none', md: 'flex' }, order:{ xs: 1, md: 1}}}>

                  <Box
                    sx={{
                      display:'flex',
                      flexDirection: 'column',
                      height: '100%', // Ensure the box takes full height of the parent                      
                    }}
                  >
                    <Grid container direction="column" sx={{ flexGrow: 1 }}>
                      <Grid item>
                        <Box>
                          <NestedList links={links}/>
                        </Box>
                      </Grid>
                      <Grid item sx={{ mt: 'auto'}}>
                        <Box>
                          <Box sx={{textAlign:'left', alignSelf:'flex-end'}}>
                            <Typography sx={{ fontSize: 14,  textTransform: 'uppercase' }} color="text.secondary">
                              Your private sex-coach
                            </Typography>               
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              variant="dot"
                            >
                              <Avatar alt="Remy Sharp" src="/yfxd47BFChKz21Y_1713078999.png" />
                            </StyledBadge>            
                            <Typography variant="overline" sx={{marginLeft:1}}><b>Leila</b></Typography>
                            <p>Feel free to ask our relationship and sexuality experts.</p>            
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                </Grid>

                
                <Grid 
                xs={12} 
                md={5} 
                sx={{ p:3, overflow:'auto', textAlign:'justify', order:{ xs: 2, md: 2}, height:{ md: '100%'}, scrollBehavior: 'smooth'  }}
                data-bs-spy="scroll" 
                data-bs-target="#navbar-scrollspy-container" 
                data-bs-smooth-scroll="true"
                tabindex="0">

                  <section id="section-1">
                    <Typography variant="h1" sx={{ }}>Intimate Wellness Assistant:<br/><b>Your Confidential Sex and Relationship Guide</b></Typography>
                    <Box>                
                      <p>We are excited to introduce our new, secure, and discreet chat service where you can receive expert answers to your relationship and intimate questions. Whether it's about love, sexuality, or any personal topic, you can share your thoughts and queries in complete anonymity here. We guarantee that all conversations are encrypted and confidential, so feel free to reach out to us to find answers and support in the most personal areas of your life.</p>
                      <p>Join us and discover understanding, guidance, and expert advice in a space where you and your feelings are the top priority!</p>
                    </Box>
                  </section>

                  <section id="section-2">
                    <Typography variant="h2" sx={{ mt:6, pt:3, textTransform: 'uppercase' }}>
                      What you can ask about
                    </Typography>
                    <Box>
                      <ul>
                        <li>Relationship Dynamics and Conflict Resolution:
                        <ul>
                          <li>How to resolve conflicts?</li>
                          <li>Communication strategies in a relationship.</li>
                        </ul>
                        </li>
                        <li>Intimacy and Sexuality:
                        <ul>
                          <li>Sexual health and hygiene.</li>
                          <li>Trying new things in the bedroom.</li>
                        </ul>
                        </li>
                        <li>Self-Esteem and Body Image:
                        <ul>
                          <li>How to boost self-confidence?</li>
                          <li>Developing and maintaining a positive body image.</li>
                        </ul>
                        </li>
                        <li>Sexual Orientation and Identity:
                        <ul>
                          <li>LGBTQ+ questions and answers.</li>
                          <li>Coming out and self-acceptance.</li>
                        </ul>
                        </li>
                        <li>Healthy Sexual Practices:
                        <ul>
                          <li>Safe sex and contraception.</li>
                          <li>Sexual wellness and pleasure.</li>
                        </ul>
                        </li>

                        <li>Dating and Finding a Partner:
                        <ul>
                          <li>Online dating tips and tricks.</li>
                          <li>First date etiquette and preparation.</li>
                        </ul>
                        </li>

                        <li>Emotional Well-being in Relationships:
                        <ul>
                          <li>Managing emotional stress and anxiety.</li>
                          <li>Building and maintaining trust.</li>
                        </ul>
                        </li>
                        <li>Consent and Boundaries:
                        <ul>
                          <li>Understanding and communicating consent.</li>
                          <li>Setting and respecting personal boundaries.</li>
                        </ul>
                        </li>
                      </ul>                  
                    </Box>
                  </section>

                  <section id="section-3">
                    <Typography variant="h2" sx={{ mt:6, pt:3, textTransform: 'uppercase' }}>
                      What you can't ask about
                    </Typography>             
                    <Box>
                      <ul>
                      <li>Sexually explicit content: <ul><li>Topics involving explicit sexual content are prohibited.</li></ul></li>
                      <li>Violence and coercion: <ul><li>Any content promoting violence, coercion, or non-consensual behavior is not allowed.</li></ul></li>
                      <li>Harassment and abuse: <ul><li>Discussions or solicitations related to harassment or abuse, including sexual harassment, are prohibited.</li></ul></li>
                      <li>Illegal activities: <ul><li>Content related to illegal sexual activities or behaviors is strictly forbidden.</li></ul></li>
                      <li>Inappropriate solicitation of personal information: <ul><li>Asking for or sharing personal information in an inappropriate or unethical manner, especially related to sexual matters, is not permitted.</li></ul></li>
                      </ul>                  
                    </Box>              
                  </section>

                  <section id="section-4">
                    <Typography variant="h2" sx={{  mt:6, pt:3, textTransform: 'uppercase' }}>
                      {/* Experts */}
                    </Typography>
                    <Grid container>             
                      <ExprtsCard />
                    </Grid>
                  </section>
                  <section id="section-5">
                    <Typography variant="h2" sx={{  mt:6, pt:3, textTransform: 'uppercase' }}>
                      Articles
                    </Typography>
                    
                    <Blog />
                  
                  </section>
                </Grid>


                <Grid id="section-6" xs={12} md sx={{ p:0, padding:1, backgroundColor: '#F2F2F2', background: 'linear-gradient(72deg, rgba(240, 240, 240, 1) 0%, rgba(210, 210, 210, 1) 100%)', height:'100%', order:{ xs: 3, md: 3}, height:{ xs:'auto', md: '100%'}, minHeight:'400px' }} className="chat-container-outer">                    
                  <Chat />
                </Grid>

              </Grid>
  );
}

export default Home;