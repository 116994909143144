import "../App.scss";

import React, { useState, useEffect } from "react";
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Unstable_Grid2';
import { TextField, Button, Box, Container, Typography } from '@mui/material';



function Contact() {

    const [buttonCaption, setButtonCaption] = useState("Send the message");
    const [status, setStatus] = useState({s:"info",c:null});    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setButtonCaption("Sending...");

      const details = {
        'name': name,
        'email': email,
        'message': message,
      };

      try {
        const response = await fetch("/api/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(details),
        });
  
        // Ellenőrizzük, hogy a válasz sikeres volt-e
        if (response.ok) {
          const data = await response.json(); // A sikeres válasz feldolgozása
          setButtonCaption("Message sent successfully!");
          setStatus({s:"success",c:"<b>Message sent successfully!</b>"});
        } else {
          setButtonCaption("Failed to send message.");
          setStatus({s:"warning",c:"<b>Failed to send message:</b><br>"+response.statusText});
        }
      } catch (error) {
        console.error("Hiba történt a fetch kérés során:", error);
        setButtonCaption("An error occurred");
        setStatus({s:"error",c:"<b>An error occurred:</b><br>"+error.message});
      }

      // Form reset
      setName('');
      setEmail('');
      setMessage('');
    };    

  return (
    <Grid container justifyContent="center" alignItems="center" direction="column">  
      <Grid xs={12} sx={{p:2, textAlign:'left'}} maxWidth="sm">
        <Typography variant="h2" sx={{ mt:2, py:3, textTransform: 'uppercase' }}><strong>Welcome to the Lustiq Sex Coach contact page!</strong></Typography>
        <p>Do you have questions about our services or want to discuss your unique needs? Fill out the form below, and our team will get back to you shortly.</p>
      </Grid>
      <Grid xs={12} sx={{p:2}} maxWidth="sm">
          <Container>
          <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
              }}
          >
              <Typography variant="h4" component="h1" gutterBottom>
              Contact Us
              </Typography>
              <TextField
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
              />
              <TextField
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
              />
              <TextField
              label="Message"
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              required
              multiline
              rows={4}
              />
              <Button variant="contained" color="primary" type="submit">
                  {buttonCaption}
              </Button>
          </Box>
          <Box sx={{ mt:3, display: status.s != "info" ? 'inherit' : 'none' }}>
            <Alert severity={status.s}>
                <span dangerouslySetInnerHTML={{ __html: status.c }} />
            </Alert>          
          </Box>
          </Container>
        </Grid>
        <Grid xs={12} sx={{p:2, mt:4, textAlign:'left'}} maxWidth="sm">
          <p>We prioritize anonymity and safety, so feel free to share your thoughts, questions, and suggestions. Whether it's about building relationships, intimacy, or sexual health, we're here to help. Our goal is to support you in creating happier and more fulfilling connections.</p>
          <p>Reach out with confidence, we look forward to hearing from you!</p>
        </Grid>      

    </Grid>
  );
}

export default Contact;