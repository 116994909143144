import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';

const actions = [
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
];

export default function BasicSpeedDial({subPages}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Backdrop open={open} />    
      <SpeedDial
        ariaLabel="Lustiq sex chat mobile buttons"
        sx={{ position: 'fixed', bottom: 90, right: 16, display: { xs: 'flex', md: 'none'} }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}  
        open={open}             
      >
        {subPages.map((action) => (
          <SpeedDialAction
            key={action.href}
            icon=<Link href={action.href}>{action.icon}</Link>
            tooltipTitle={
              <Tooltip className="text-nowrap text-red">
                <span>{action.text}</span>
              </Tooltip>
            }
            tooltipOpen 
            to={action.href}
            onClick={handleClose}            
          />
        ))}
          <SpeedDialAction
            icon=<Link href="#section-6"><MarkUnreadChatAltIcon /></Link>
            tooltipTitle={
              <Tooltip className="text-nowrap text-red">
                <span>Chat</span>
              </Tooltip>
            }
            tooltipOpen 
            to="#section-6"
            onClick={handleClose}            
          />        
      </SpeedDial>
    </Box>
  );
}
