import "./App.scss";

import React, { useState, useEffect } from "react";
import Home from "./Pages/Home.js";
import Howtouse from "./Pages/Howtouse.js";
import Contact from "./Pages/Contact.js";
import ResponsiveAppBar from "./Components/ResponsiveAppBar.js";
import { styled,createTheme, ThemeProvider } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

const pages = [ {title:'Chat',component:Home,url:"/"}, {title:'How to Use', component:Howtouse, url:"/howtouse"}, { title:'Contact',component:Contact, url:"/contact"}];
const subPages = [ {title:'Chat',component:Home,url:"/"}, {title:'How to Use', component:Howtouse, url:"/howtouse"}, { title:'Contact',component:Contact, url:"/contact"}];

const theme = createTheme({
  typography: {
    fontSize:16,
    h1: {
      fontSize: 30,
    },
    h2: {
      fontSize: 22,  
      fontWeight: 600
    },
    h3: {
      fontSize: 18
    },  
    overline:{
      fontSize: 16,
      color:'#97DEF0'         
    }      
  },  
  palette: {
    primary: {
      main: '#fa2243'      
    },
    secondary: {
      main: '#97DEF0'      
    },
    listItem:{
      selected:{
        backgroundColor: "#97DEF055"
      },
      hover:{
        backgroundColor: '#97DEF099'
      },   
    }
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function App() {

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(!isMobile);

  useEffect(() => {
    setExpanded(!isMobile);
  }, [isMobile]);

  const handleChange = () => {
    setExpanded(!expanded);
  };  

  return (
    <ThemeProvider theme={theme}>

      <Box className="App">

        <ResponsiveAppBar pages={pages} />

        <Router>

          <Routes>
            {pages.map((page) => {
              return  <Route key={page.url} path={page.url} element={<page.component />} />                      
            })}
          </Routes>                  

        </Router>
      
      </Box>

    </ThemeProvider>
  );
}

export default App;