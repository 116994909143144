import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import LinearProgress from '@mui/material/LinearProgress';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';


const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Function to create a new thread and store its ID
  const getBlogList = async () => {
    try {
      const apiUrl = "/api/shopify/article/list"; // Ensure this matches your API endpoint for creating a thread
      const { data } = await axios.get(apiUrl);
      console.log(data);
      setArticles(data); // Assuming the API returns an object with a threadId property
      setIsLoading(false);
    } catch (error) {
      console.error("Error get blog list:", error);
    }
  };

  // Call createThread when the component mounts
  useEffect(() => {
    getBlogList();
  }, []);

  return (
    <Grid container className="blog-container">
      {isLoading && <Box sx={{ width: '100%' }}><LinearProgress /></Box>}
      {articles.map((article, index) => (
      <Grid xs={12} sx={{p:1}}>
        <Box key={index} className="blog-article-container" sx={{ marginTop:3 }}>
          <Card key={index} sx={{ width:'100%' }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  R
                </Avatar>
              }
              title={article.title}
              subheader={article.published_at}
            />
            <CardMedia
              component="img"
              height="140"
              image={article.image.src+"&height=340"}
              alt={article.title}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{__html: article.summary_html }} />
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
              <Button variant="contained" href={'https://lustiq.eu/blogs/news/'+article.handle} endIcon={<MoreHorizIcon />}>
                View
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Grid>
      ))}
    </Grid>
  );
};

export default Blog;